import React from "react"
import * as Style from "./TermList.module.scss"
import Markdown from "react-markdown"
import Button from "../UI/custom-button/CustomButton"

const TermList = ({ termsArray }) => {
  const downloadFileHandler = () => {
    fetch("/terms.docx").then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement("a")
        a.href = url
        a.download = "terms.docx"
        a.click()
      })
    })
  }

  return (
    <section className={`${Style.informationSectionWrapper} main-container`}>
      <div className={Style.contentWrapper}>
        {termsArray.map((item, index) => {
          return (
            <div className={Style.innerContentWrapper} key={index}>
              <div className={Style.headigWrapper}>
                <Markdown children={item.heading.data.heading} />
              </div>
              <div className={Style.mainText}>
                <Markdown children={item.par.data.par} />
              </div>
            </div>
          )
        })}
      </div>
      <div className={Style.downloadWrapper}>
        <Button
          buttonHandler={downloadFileHandler}
          global={true}
          style={Style.downloadLink}
        >
          Свали докумнета
        </Button>
        {/* <a
          className={Style.downloadLink}
          href="../../assets/files/terms.odt"
          download
        >
          Свали докумнета
        </a> */}
      </div>
    </section>
  )
}

export default TermList
