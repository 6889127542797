import React from "react"
import * as Style from "./SectionHeading.module.scss"
import Markdown from "react-markdown"

const SectionHeading = ({ heading, mode }) => {
  return (
    <div
      className={`${Style.heading} ${
        mode === "useful" && Style.usefulHeading
      } main-container`}
    >
      <Markdown children={heading} />
    </div>
  )
}

export default SectionHeading
