import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import TermList from "../components/term-list/TermList"
import SectionHeading from "../components/UI/section-heading/SectionHeading"

export const query = graphql`
  query {
    strapiTerm {
      pageHeading {
        data {
          pageHeading
        }
      }
      headingParTerm {
        heading {
          data {
            heading
          }
        }
        par {
          data {
            par
          }
        }
      }
    }
  }
`

const Terms = ({ data }) => {
  const pageHeading = data.strapiTerm.pageHeading.data.pageHeading
  const termsArray = data.strapiTerm.headingParTerm
  return (
    <Layout>
      <SectionHeading heading={pageHeading} mode="useful" />
      <TermList termsArray={termsArray} />
    </Layout>
  )
}

export default Terms
